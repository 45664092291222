<template>
  <div class="container">
    <div class="text-content" v-html="currentHtmlContent"></div>
    <div class="audio-control">
      <audio :src="currentPage.audio" ref="audioPlayer" controls></audio>
    </div>
    <div class="buttons">
      <button @click="goToPreviousPage" :disabled="currentIndex === 0">
        Previous
      </button>
      <button
        @click="goToNextPage"
        :disabled="currentIndex === pages.length - 1"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
import pagesData from "@/assets/pages.json";

export default {
  data() {
    return {
      pages: [],
      currentHtmlContent: "",
      currentIndex: 0,
    };
  },
  computed: {
    currentPage() {
      return this.pages[this.currentIndex];
    },
  },
  created() {
    this.pages = pagesData;
    this.loadHtmlContent();
  },
  methods: {
    async loadHtmlContent() {
      try {
        const response = await fetch(this.currentPage.htmlPath);
        if (!response.ok) throw new Error("Error al cargar el archivo HTML");
        this.currentHtmlContent = await response.text();
      } catch (error) {
        console.error("Error al cargar contenido HTML:", error);
        this.currentHtmlContent = "<p>Error al cargar el contenido.</p>";
      }
    },
    goToPreviousPage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.loadHtmlContent();
        // this.playAudio();
      }
    },
    goToNextPage() {
      if (this.currentIndex < this.pages.length - 1) {
        this.currentIndex++;
        this.loadHtmlContent();
        // this.playAudio();
      }
    },
    // playAudio() {
    //   console.log("Function playAudio just triggered");
    //   const audioPlayer = this.$refs.audioPlayer;
    //   if (audioPlayer) {
    //     audioPlayer.load();
    //     const playPromise = audioPlayer.play();
    //     if (playPromise !== undefined) {
    //       playPromise
    //         .then(() => {
    //           console.log("Reproducción automática iniciada correctamente.");
    //         })
    //         .catch((error) => {
    //           console.error(
    //             "La reproducción automática fue bloqueada o falló.",
    //             error
    //           );
    //         });
    //     }
    //   }
    // },
  },
};
</script>

<style>
body {
  display: flex;
  justify-content: center; /* Centrado horizontal */
  align-items: center;    /* Centrado vertical */
  min-height: 100vh;      /* Ocupa todo el alto de la ventana */
  margin: 0;              /* Elimina márgenes por defecto */
  background-color: #f4f4f4; /* Fondo opcional */
}

.container {
  max-width: 700px;
  text-align: center; /* Centrado del texto */
  padding: 20px; /* Espaciado opcional */
  background-color: #fff; /* Fondo del contenedor */
  border-radius: 10px; /* Esquinas redondeadas */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra opcional */
}

.text-content {
  text-align: left;
  margin-bottom: 20px;
}

.text-content h1 {
  text-align: center;
  font-size: 28px;
  margin-bottom: 10px;
}

.text-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: gray;
}

.text-content h3 {
  font-size: 22px;
  margin-bottom: 10px;
  color: gray;
}

.text-content p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.text-content ol {
  margin-left: 20px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  font-size: 1em;
  padding: 10px 20px;
  cursor: pointer;
}

button:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.audio-control {
  margin-bottom: 20px;
  text-align: center;
}
</style>
